import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import {
  GridContainer,
  GridItemFullWidth,
  GridItem,
} from "../components/layout/Grid"
import Seo from "../components/Seo"
import H3Header from "../components/H3Header"
import Person from "../components/Person"
import BlogPostPreview from "../components/BlogPostPreview"

const CenteredParagraph = styled.p`
  text-align: center;
`

const PersonTemplate = ({
  data: {
    teamJson: { fullName, firstName, about, image, position },
    allMdx,
  },
}) => {
  const hasBlogPosts = allMdx.totalCount > 0

  return (
    <Layout>
      <Seo title={fullName} />
      <GridContainer>
        <GridItemFullWidth>
          <Person
            about={about}
            image={image}
            fullName={fullName}
            position={position}
          />
        </GridItemFullWidth>
        <GridItemFullWidth>
          <H3Header>Latest Blog Posts</H3Header>
          {!hasBlogPosts && (
            <CenteredParagraph>{firstName} has no blog posts</CenteredParagraph>
          )}
        </GridItemFullWidth>

        {allMdx.nodes.map(node => (
          <GridItem key={node.id}>
            <BlogPostPreview data={node} hideAuthor />
          </GridItem>
        ))}
      </GridContainer>
    </Layout>
  )
}

export default PersonTemplate

export const pageQuery = graphql`
  query($id: String!) {
    teamJson(id: { eq: $id }) {
      fullName
      firstName
      about
      image
      position
    }

    allMdx(
      filter: { fields: { author: { id: { eq: $id } } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      totalCount
      nodes {
        ...BlogPostPreview
      }
    }
  }
`
