import React from "react"
import styled from "styled-components"

const TagList = styled.ul`
  list-style: none;
  padding: 0;

  display: flex;
  flex-wrap: wrap;
`
const TagListItem = styled.li`
  display: block;
  white-space: nowrap;

  margin-right: 10px;
  margin-bottom: 5px;
  padding: 5px 10px;
  border-radius: 5px;

  background-color: var(--progwise-blue);
  color: #fff;
  font-size: 80%;
  font-weight: bold;

  ::before {
    content: "#";
  }

  :last-child {
    margin-right: 0;
  }
`

interface PostCategoriesProps {
  readonly categories: string[]
}

const PostCategories: React.FC<PostCategoriesProps> = ({ categories }) => (
  <TagList>
    {categories.map(category => (
      <TagListItem key={category}>{category}</TagListItem>
    ))}
  </TagList>
)

export default PostCategories
