import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import PostCategories from "./PostCategories"

const BlogPostHeader = styled.h4`
  text-align: center;
  & a {
    text-decoration: none;
  }
`

const CenteredParagraph = styled.p`
  text-align: center;
`

const Subtitle = styled.p`
  text-transform: uppercase;
  margin: 2px 0;
  text-align: center;

  & a {
    color: var(--progwise-blue);
    text-decoration: none;
  }
`

const ExcerptParagraph = styled.p`
  & a {
    text-decoration: none;
  }
`

interface BlogPostPreviewProps {
  data: {
    frontmatter: {
      title: string
      path: string
      date: string
      isoDate: string
      categories: string[]
    }
    fields: {
      author: {
        id: string
        fullName: string
      }
    }
    excerpt: string
  }
  hideAuthor?: boolean
}

const BlogPostPreview: React.FC<BlogPostPreviewProps> = ({
  data: {
    frontmatter: { path, title, date, isoDate, categories },
    fields: { author },
    excerpt,
  },
  hideAuthor = false,
}) => {
  const blogPostUrl = `/blog/${path}`
  const authorPageUrl = `/team/${author.id}`
  return (
    <article>
      <header>
        <BlogPostHeader>
          <Link to={blogPostUrl}>{title}</Link>
        </BlogPostHeader>
        {!hideAuthor && (
          <CenteredParagraph>
            Written by <Link to={authorPageUrl}>{author.fullName}</Link>
          </CenteredParagraph>
        )}
        <Subtitle>
          <Link to={blogPostUrl}>
            <time dateTime={isoDate}>{date}</time>
          </Link>
        </Subtitle>
      </header>
      <section>
        <ExcerptParagraph>
          <Link to={blogPostUrl}>{excerpt}</Link>
        </ExcerptParagraph>
      </section>
      {categories && (
        <footer>
          <PostCategories categories={categories} />
        </footer>
      )}
    </article>
  )
}

export default BlogPostPreview

export const query = graphql`
  fragment BlogPostPreview on Mdx {
    id
    frontmatter {
      path
      title
      date(formatString: "MMMM DD, YYYY")
      isoDate: date
      categories
    }
    fields {
      author {
        id
        fullName
      }
    }
    excerpt
  }
`
