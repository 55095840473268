import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

interface ProfilImageProps {
  source: string
  fullName: string
  rounded?: boolean
}

const ProfilImage: React.FC<ProfilImageProps> = ({
  source,
  fullName,
  rounded = false,
}) => {
  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fixed(width: 162, height: 162, quality: 100, cropFocus: CENTER) {
              ...GatsbyImageSharpFixed
              originalName
            }
          }
        }
      }
    }
  `)

  const image = data.allImageSharp.edges.find(
    edge => edge.node.fixed.originalName === source
  )
  if (!image) {
    return null
  }

  return (
    <Img
      fixed={image.node.fixed}
      style={{ margin: "0 auto", borderRadius: rounded ? "100%" : 0 }}
      alt={fullName}
    />
  )
}

export default ProfilImage
