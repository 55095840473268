import React from "react"
import styled from "styled-components"

import ProfilImage from "./ProfilImage"
import H2Header from "./H2Header"

const CenterDiv = styled.div`
  display: flex;
`

const Position = styled.p`
  text-transform: uppercase;
  color: var(--progwise-blue);
  margin: 2px 0;
  text-align: center;
`

const About = styled.p`
  text-align: center;
`

interface PersonProps {
  readonly image: string
  readonly fullName: string
  readonly position: string
  readonly about?: string
  readonly nameAs?: React.ComponentType
}

const Person: React.FC<PersonProps> = ({
  image,
  fullName,
  position,
  about,
  nameAs: NameComponent = H2Header,
}) => {
  return (
    <>
      <CenterDiv>
        <ProfilImage source={image} fullName={fullName} rounded />
      </CenterDiv>
      <NameComponent>{fullName}</NameComponent>
      <Position>{position}</Position>
      {about && <About>{about}</About>}
    </>
  )
}

export default Person
